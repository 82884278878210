




import { BaseComponent, Component } from '@/components/BaseComponent';

@Component
export default class Callback extends BaseComponent {
  async created() {
    const params = new URLSearchParams(window.location.search);
    const result = await this.pds.callback(params);
    this.router.changePage(result);
  }
}
